.glass{
    @apply h-5/6 w-5/6;
}

.profile_img{
    @apply w-40 cursor-pointer;
}

.textbox{
    @apply w-3/4;
}