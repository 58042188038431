
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap');


html,
body,
#root,
.app {

    height: 100%;
    width: 100%;
    font-family: "Inter", sans-serif;
}

body{
    background: #dcdce6;
    /*background-image: url('./assets/Background.png');*/
}